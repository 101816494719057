import { netpost } from '../plugins/request'

export default {
  // 创建分享
  createShareWaybill(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/createShareWaybill`, params)
  },
  // 验证分享链接
  verifyShareWaybillUrl(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/VerifyShareWaybillUrl`, params)
  },
  // 分享运单信息
  getShareWaybill(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetShareWaybill`, params)
  },
  // 通过运单id获取运单商品
  getWaybillGoods(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/GetWaybillGoods', params)
  },
  // 通过运单号获取快递公司
  getDeliveryCompany(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/GetDeliveryCompany', params)
  },
  // 运单跟踪
  getTraces(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/GetTraces', params)
  },
  // 分享记录
  getWaybillShareList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/GetWaybillShareList', params)
  },
  // 禁用运单分享
  disableWaybillList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/DisableWaybillList', params)
  },
  // 记录运单分享点击次数（同一次浏览 session，同一code只记录一次 防止频繁刷新）
  clickWaybillShare(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/ClickWaybillShare', params)
  }
}
