<!-- 运单轨迹 -->
<template>
  <div>
    <div v-if="isError" class="error-page">
      <div class="error-message">
        <i class="el-icon-warning"></i>{{ errorMessage }}
      </div>
    </div>
    <div class="pwd-page">
      <div class="pwd-content">
        <div class="pc-container">
          <el-input
            placeholder="输入物流单号"
            size="medium"
            v-model.trim="deliveryno"
          >
          </el-input>
          <el-button
            type="primary"
            @click="handleShowTrace()"
            size="medium"
            icon="el-icon-search"
            >轨迹查询</el-button
          >
        </div>
        <div class="wap-container">
          <el-input
            placeholder="输入物流单号"
            size="medium"
            v-model.trim="deliveryno"
          >
          </el-input>
          <el-button
            type="primary"
            @click="handleShowTrace('WAP')"
            size="medium"
            icon="el-icon-search"
            >轨迹查询</el-button
          >
        </div>
      </div>
      <div class="disclaimer-but" @click="disclaimer = true">
        199.JP 免责声明
      </div>
    </div>
    <!-- 包裹明细 end  -->
    <el-drawer
      :title="deliveryno"
      :append-to-body="true"
      :visible.sync="innerDrawer"
      :size="isPc ? '600px' : '100%'"
    >
      <div class="trace-container">
        <div class="items">
          <div class="trace-title">
            <div class="iconfont icon-wuliuquanqiuwuliu"></div>
            <span class="bold">物流信息</span>
          </div>
          <div class="line" v-for="(item, index) in traceList" :key="item.time">
            <div :class="['dot-content', index == 0 ? 'start' : '']">
              <div class="dot"></div>
              <div class="hr" v-if="index != traceList.length - 1"></div>
            </div>
            <div :class="['trace-content', index == 0 ? 'start' : '']">
              <div>{{ item.content }}</div>
              <div class="time">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer
      title="199.JP 免责声明"
      :visible.sync="disclaimer"
      direction="btt"
      :size="'100%'"
      :before-close="handleCloseDisclaimer"
    >
      <div class="disclaimer-content">
        <b> **免责声明**</b>
        <p>
          199提供的查询服务只供参考使用。查询结果可能与快递公司官方查询结果有所不同，不能作为投诉索赔的依据。本网站不对物流运营商信息的合理性、准确性和完整性作出保证，也不对因其不合理、不准确或遗漏而导致的任何损失或损害承担责任，包括但不限于直接、间接、偶然、惩罚性或引起的损失。
        </p>
        <p>
          199不提供任何形式的保证，包括但不限于物流服务商跟踪服务不会中断、物流商对信息的及时更新、安全、准确，以及您通过本网站获得的任何信息的完整性和准确性，或纠正软件中的任何错误。在任何情况下，199都不承担因使用本网站信息而产生的任何直接或间接的损害赔偿责任。由199提供的运营商和运营商的联系方式，只用于方便用户参考信息作用，不做交易和服务的根据。
        </p>
        <p>
          本网站不对黑客攻击、计算机病毒侵入或发作、政府管制等造成的暂时性关闭等不可抗力因素所造成的损失负责。对于由于与本网站链接的其他网站所造成之个人资料泄露及由此而导致的任何法律争议和后果，本网站不承担责任。
        </p>
        <p>
          若因线路及非本公司控制范围外的硬件故障或其他不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本网站不负任何责任。
        </p>
        <p>
          使用本网站的用户若触犯中华人民共和国法律，一切后果自负，本网站概不承担责任。
        </p>
        <p>
          凡以任何方式登录本网站或直接、间接使用本网站资料者，视为自愿接受本网站声明的约束。
        </p>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Vue from 'vue'
import ElDrawer from 'element-ui/packages/drawer'
import WaybillShare from 'api/waybill-share'
Vue.use(ElDrawer)
export default {
  components: {},
  name: '',
  data() {
    return {
      disclaimer: false,
      code: '',
      isPc: false,
      pcDrawer: false,
      innerDrawer: false,
      isError: false,
      errorMessage: '',
      deliveryno: '',
      traceList: [] // 物流信息
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    // 显示免责
    handleShowDisclaimer() {
      this.disclaimer = true
    },
    // 关闭免责
    handleCloseDisclaimer() {
      this.disclaimer = false
    },
    // 查看物流信息
    handleShowTrace(page = 'PC') {
      if (this.deliveryno) {
        if (page == 'PC') {
          this.isPc = true
        } else {
          this.isPc = false
        }
        console.log('isPC ===', this.isPc, page)
        this.innerDrawer = true
        this.getTrace(this.deliveryno)
      }
    },

    getTrace(key) {
      this.traceList = []
      WaybillShare.getTraces({ key })
        .then((res) => {
          console.log('get trace res:', res)
          if (res.success) {
            this.traceList = res.record
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(res.message)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.waybill {
  margin: 20px 0 30px;
  background: #fff;
  box-shadow: 0px 0px 6px -3px #593c7d;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  .pc-line.pl-country {
    background: #f8f8f8;
  }
}
.way-waybill {
  margin: 20px 0;
}
.share-page {
  padding: 0 15px;
  .pc-container {
    width: 960px;
    margin: auto;
    flex-direction: column;
    height: 100vh;
  }
  .wap-container {
    flex-direction: column;
    height: 100vh;
    .way-table {
      flex: 1;
    }
    .wap-row-country {
      display: flex;
      justify-content: space-between;
      .country {
        display: flex;
        align-items: center;
        font-weight: bold;
        img {
          height: 20px;
          border: 1px solid #f1f1f1;
          border-radius: 4px;
          margin-left: 4px;
        }
      }
    }
    .wap-row {
      display: flex;
      margin-top: 8px;
      .key {
        width: 70px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: nowrap;
        img {
          width: 100%;
        }
      }
      .value {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        text-align: left;
      }
      .value.delivery {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .pc-line {
    display: flex;
    justify-content: space-between;
    .arrow {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
    }
    .country {
      font-size: 18px;
      font-weight: bold;
      height: 44px;
      display: flex;
      justify-content: center;
      flex: 1;
      border-bottom: 1px solid #f1f1f1;
      align-items: center;
      img {
        height: 20px;
        border: 1px solid #f8f8f8;
        margin-left: 5px;
        border-radius: 4px;
      }
    }
    .l-content,
    .r-content {
      flex: 1;
      display: flex;
    }
    .key {
      width: 100px;
      display: inline-block;
      background: #f8f8f8;
      min-height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #f1f1f1;
      img {
        width: 100%;
      }
    }
    .value {
      flex: 1;
      min-height: 44px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      padding-left: 10px;
    }
    .value.number {
      // font-size: 16px;
      // font-weight: bold;
    }
    .value:last-of-type {
      border-right: 1px solid #f1f1f1;
    }
    .delivery {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
  .pc-line:first-of-type {
    margin-top: 0;
  }
  .disclaimer {
    text-align: center;
    background: #ececec;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4d1c8a;
    cursor: pointer;
  }
}
.error-page {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-message {
    font-size: 16px;
    color: #aaa;
    font-weight: bold;
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
      margin-right: 5px;
    }
  }
}
.pwd-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  .pwd-content {
    // width: 180px;
    display: flex;
    background: #efefef;
    width: 100%;
    justify-content: center;
    padding: 40px;
    ::v-deep {
      .el-input__inner {
        border-radius: 4px 0 0 4px;
      }
    }
  }
  .disclaimer-but {
    position: fixed !important;
    height: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ccc;
    color: #4d1c8a;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.cc-container {
  // 快递信息
  background: #f8f8f8;
  .tracking-content {
    margin-top: 16px;
    background: #fff;
    padding: 16px;
    border-radius: 6px 6px 0 0;
    display: flex;
    justify-content: space-between;
    .logo {
      image {
        object-fit: contain;
      }
    }
    .number {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 12px;
      font-size: 22px;
      font-weight: bold;
      .note {
        position: absolute;
        font-size: 12px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        color: #68488f;
        margin-top: 50px;
        cursor: pointer;
      }
    }
    .icon-fenxiangfangshi {
      color: #68488f;
      font-size: 22px;
    }
  }
  // 重量 运费
  .weight-price {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    color: rgba($color: #000000, $alpha: 0.5);
    border-bottom: 1px solid #ddd;
    .weight,
    .price {
      display: flex;
      font-size: 14px;
      height: 56px;
      display: flex;
      align-items: center;
      .value {
        font-size: 17px;
        font-weight: bold;
        margin-left: 8px;
      }
    }
    .weight {
      .value {
        color: #fa5151;
      }
    }
    .price {
      .value {
        display: flex;
        align-items: center;
        color: #07c160;
        .icon-jiage {
          font-size: 14px;
        }
      }
    }
  }
  // 包裹
  .pack-content {
    display: flex;
    background: #fff;
    align-items: center;
    padding: 16px;
    border-radius: 0 0 6px 6px;
    .qrcode {
      .icon {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .number {
      margin-left: 12px;
      display: flex;
      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.5);
      .value {
        margin-left: 8px;
        color: #2c2c2c;
      }
    }
  }
  // 寄收信息
  .send-receipt {
    margin-top: 16px;
    background: #fff;
    border-radius: 6px;

    .receipt,
    .send {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 0 16px;
      overflow: hidden;

      .logo {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;

        .name {
          padding: 3px;
          border-radius: 6px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
          font-size: 14px;
        }
      }

      .sr-content {
        flex: 1;
        display: flex;
        padding: 16px 0;
        margin-left: 12px;
        align-items: center;

        .content {
          flex: 1;
          position: relative;
          .valid {
            background: #07c160;
            color: #fff;
            padding: 2px 6px;
            width: max-content;
            font-size: 12px;
            border-radius: 0 0 0 6px;
            position: absolute;
            top: -16px;
            right: 0px;
            transform: rotate(0deg);
          }
          .title {
            font-size: 15px;
          }

          .info {
            font-size: 14px;
          }
        }
      }
    }

    .receipt {
      .logo {
        .hr {
          position: absolute;
          top: 0%;
          bottom: calc(50% + 20px);
          width: 1px;
          background: #ddd;
        }
        .name {
          background: #ffc300;
        }
      }
    }

    .send {
      .logo {
        .hr {
          position: absolute;
          top: calc(50% + 20px);
          bottom: 0;
          width: 1px;
          background: #ddd;
        }
        .name {
          background: #1485ee;
        }
      }

      .sr-content {
        border-bottom: 1px solid #ddd;
      }
    }
  }
  .product-content {
    background: #fff;
    border-radius: 6px;
    margin-top: 16px;
    padding: 0 16px;
    .title {
      padding: 16px 0;
      border-bottom: 1px solid #ddd;
      color: rgba($color: #000000, $alpha: 0.5);
    }
    .product-title {
      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.5);
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
    }
    .product {
      display: flex;
      font-size: 14px;
      padding: 16px 0;
      margin-bottom: 16px;
      border-bottom: 1px solid #ddd;
      .img {
        margin-right: 12px;
        image {
          width: -webkit-fill-available;
          height: inherit;
          object-fit: contain;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        flex: 1;
        .name {
          flex: 1;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .code {
          color: rgba($color: #000000, $alpha: 0.5);
        }
      }
      .qty {
        width: 40px;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .price {
          display: flex;
          color: #fa5151;
          align-items: center;
          .icon-jiage {
            font-size: 12px;
            margin-top: 2px;
            margin-right: 4px;
          }
        }
      }
    }
    .product:last-of-type {
      border-bottom: 0;
    }
  }
}

.trace-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .but {
    background: #fff;
    height: 44px;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
  }
  .items {
    flex: 1;
    overflow-y: auto;
    padding: 0 16px;
    .trace-title {
      height: 56px;
      display: flex;
      align-items: center;
      font-size: 17px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 12px;
      .iconfont {
        font-size: 28px;
        margin-top: 2px;
        margin-right: 8px;
      }
      .bold {
        font-weight: bold;
      }
    }
    .line {
      display: flex;
      .dot-content {
        width: 20px;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-right: 16px;
        .dot {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background: #aaa;
          position: absolute;
          z-index: 1;
          top: 16px;
        }
        .hr {
          height: calc(100% - 4px);
          width: 1px;
          background: #aaa;
          position: relative;
          top: 14px;
        }
      }
      .dot-content.start {
        .dot,
        .hr {
          background: #07c160;
        }
        .dot {
          animation-name: currdot;
          animation-duration: 0.5s;
          animation-timing-function: linear;
          animation-delay: 1s;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
      }
      .trace-content {
        flex: 1;
        margin-bottom: 4px;
        background: #f8f8f8;
        padding: 10px;
        border-radius: 4px;
        .time {
          margin-top: 4px;
          font-size: 14px;
          margin-left: 15px;
          color: #999;
        }
      }

      .trace-content.start {
        background: rgba(65, 152, 68, 0.11);
      }
    }
  }
}
img {
  object-fit: contain !important;
}
@keyframes currdot {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
.disclaimer-content {
  padding: 15px;
  b {
    text-align: center;
    width: 100%;
    display: block;
    font-size: 16px;
  }
  p {
    text-indent: 2em;
  }
}
::v-deep {
  .el-table__body tr.hover-row > td {
    background-color: #fff !important;
  }
  .el-drawer__header {
    font-weight: bold;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 599px) {
  .share-page {
    padding: 0;
  }
  .pc-container {
    display: none;
  }
  .wap-container {
    display: flex;
  }
}
@media (min-width: 599px) {
  .share-page {
    padding: 0;
  }
  .pc-container {
    display: none;
  }
  .wap-container {
    display: flex;
  }
}
@media (min-width: 960px) {
  .share-page {
    padding: 0 15px;
  }
  .pc-container {
    display: flex;
  }
  .wap-container {
    display: none;
  }
}
@media (min-width: 990px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1440px) {
}
</style>
